import Intro from './Intro';
import ProjectShuffle from './ProjectShuffle';



const Home = () => {
  return (
    <>
      <Intro />
      <ProjectShuffle />
    </>
  );
};

export default Home;