import resume_1 from './assets/Resume_1.png'
import resume_2 from './assets/Resume_2.png'

const About = () => {
    return (
        <section class="container grid project-contents">
        <br></br>
            <div class="project-contents">
                <div>
                    <img src={resume_1}/>    
                </div>
                <br></br>
                <div>
                    <img src={resume_2}/>
                </div>
            </div>
        </section>
    );
  };
  
  export default About;