import './Projects.css';
import notion_img1 from '../assets/Notion-Projects.gif'
import notion_img2 from '../assets/Notion-ProjectDetails.gif'
import notion_img3 from '../assets/Notion-cmpt.gif'

const Notion = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> NOTION DATABASE FOR PROJECT SUPPORT MANAGEMENT </h3>
                <br></br>
                <div>
                    <img src={notion_img1}/>
                </div>
                <p class="project-caption"> HA Notion Pages for Project Management </p>
                <div class="project-text"> 
                Supporting multiple projects simultaneously can be challenging. To streamline this, I developed a Notion system for the Design Technology team, enabling us to provide support more efficiently. 
                </div>
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <br></br>
                <div>
                    <img src={notion_img2}/>
                </div>
                <p class="project-caption"> HA Notion Pages for Projects </p>
                <div class="project-text"> 
                    Each project has their own page containing project details on schedule, support items, team members, and notes.  
                </div>

                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <br></br>
                <div>
                    <img src={notion_img3}/>
                </div>
                <p class="project-caption"> HA Notion Pages for CMPT Development </p>
                <div class="project-text"> 
                    All workflows to support projects are also saved in Notion page.     
                </div>

                <br></br>
            </div>
        </section>
    );
}

export default Notion;