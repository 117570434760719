import './Projects.css';
import uf_img1 from '../assets/UF1.gif'
import uf_img2 from '../assets/UF2.gif'
import uf_img3 from '../assets/UF3.gif'

const UnitFinder = () => {
    return(
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> 1. Unit Finder Data Export </h3>
                <br></br>
                <div>
                    <img src={uf_img1}/>
                </div>
                <p class="project-caption"> Unit Outline from Rhino to UnitFinder </p>
                <div class="project-text"> 
                    The custom toolbar on Rhino can send the outline data to the web-based UnitFinder tool.  <br></br>
                    Once you send the outline, you can mark the enclosure and door location, so look up the closest unit plan in the database.<br></br>
                    The outline data has been fine-tuned to optimize retrieval performance.  
                </div>
                <div class="project-text"> 
                      
                </div>
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> 2. Unit Finder Data Retrieval </h3>
                <br></br>
                <div>
                    <img src={uf_img2}/>
                </div>
                <p class="project-caption"> Retrieved Data from UnitFinder back to Rhino </p>
                <div class="project-text"> 
                    Among the units that have been retrieved based on the similarity to the input plan, the user can choose what to retrieve to Rhino.	<br></br>
                    You can use the same functionality in Revit as well. 
                </div>
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> 3. Unit Finder Data Package </h3>
                <br></br>
                <div>
                    <img src={uf_img3}/>
                </div>
                <p class="project-caption"> Retrieved Data as a package </p>
                <div class="project-text"> 
                    The selected plan can be downloaded as a package including the image,  PDF, JSON, Rhino 3dm, and unit data summary report.
                </div>
                <br></br>
            </div>
        </section>
    );
}


export default UnitFinder;