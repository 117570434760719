import { Link } from 'react-router-dom';
import './WorkflowList.css';
import pages from '../../../utils/pages';
import ProjectCard from './ProjectCard';
import { workflows } from '../ProjectAsset/ProjectAsset';


const WorkflowList = () => {
return (
    <section className="container grid project-lists">
      {workflows.map((workflows, index) => 
          <ProjectCard key={index} project={workflows} />
      )}
    </section>
  );
};

export default WorkflowList;