import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'; 
import './ProjectShuffle.css';
import pages from '../../../utils/pages';
import ProjectCard from './ProjectCard';
import {devs, workflows} from '../ProjectAsset/ProjectAsset';


const projects = [...devs, ...workflows];
const firstProjects = projects.slice(0, 6);

const ProjectShuffle = () => {

  const [featuredProjects, setFeaturedProjects] = useState(firstProjects);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedProjects = [...devs, ...workflows];
    updatedProjects.sort((a, b) => 0.5 - Math.random());
    setFeaturedProjects(updatedProjects.slice(0, 6));
  }

  return (
    <section className="container grid project-shuffle">
      <div className="project-shuffle-header">
        <h3>Click to explore some additional projects!</h3>
        <button className="button-primary" onClick={handleSubmit}>
          Refresh <FontAwesomeIcon icon={faArrowRotateRight}/>
        </button>
      </div>
      {featuredProjects.map((project, index) => 
        <ProjectCard key={index} project={project} />
      )}
    </section>
  );
};

export default ProjectShuffle;