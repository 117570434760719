import logo from './logo.svg';
// import './App.css';
import {BrowserRouter, Route, Routes, HashRouter} from 'react-router-dom';
import pages from './utils/pages';
import Layout from './components/layout/Layout';
import About from './components/pages/About';
import Home from './components/pages/Home';
import WIP from './components/pages/UnderConstruction';

import ProjectDev from './components/pages/ProjectDev';

import UnitMix from './components/pages/ProjectDev/projects/UnitMix';
import UnitFinder from './components/pages/ProjectDev/projects/UnitFinder';
import Notion from './components/pages/ProjectDev/projects/Notion';
import Webpage from './components/pages/ProjectDev/projects/Webpage';

import ProjectWorkflow from './components/pages/ProjectWorkflow';

import ViewAnalysis from './components/pages/ProjectWorkflow/projects/ViewAnalysis';
import AreaCalculation from './components/pages/ProjectWorkflow/projects/AreaCalculation';
import RapidPatterning from './components/pages/ProjectWorkflow/projects/RapidPatterning';
import GeomRationalization from './components/pages/ProjectWorkflow/projects/GeomRationalization';

// import the react js file here from the location 

const App = () => {
  return (
    <>
      <Layout>
          <Routes>
            <Route path={pages.get('home').path} element={<Home />} />
            <Route path={pages.get('project-dev').path} element={<ProjectDev />} />
            <Route path={pages.get('project-workflow').path} element={<ProjectWorkflow />} />
            <Route path={pages.get('about').path} element={<About />} />
            <Route path={pages.get('under construction').path} element={<WIP />} />
            
            <Route path={pages.get('unit-mix').path} element={<UnitMix />} />
            <Route path={pages.get('unit-finder').path} element={<UnitFinder/>} />
            <Route path={pages.get('notion').path} element={<Notion/>} />
            <Route path={pages.get('webpage').path} element={<Webpage/>} />

            <Route path={pages.get('view-analysis').path} element={<ViewAnalysis/>} />
            <Route path={pages.get('area-calculation').path} element={<AreaCalculation/>} />
            <Route path={pages.get('rapid-patterning').path} element={<RapidPatterning/>} />
            <Route path={pages.get('geom-rationalization').path} element={<GeomRationalization/>} />
          </Routes>
      </Layout>
    </>
  );
};

export default App;