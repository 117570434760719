import unitmix from './assets/UnitMix_tn.png';
import unitfinder from './assets/UnitFinder_tn.jpg';
import notion from './assets/NotionDev_tn.gif';
import webpage from './assets/Webpage_tn.gif'

import areacal from './assets/AreaCalculation_tn.png';
import solarLB from './assets/SolarHoursLB_tn.jpg'
import facades from './assets/FacadeWorkflows.gif'
import cs from './assets/Climatestudio_tn.gif'
import viewanalysis from './assets/ViewAnalysis_tn.gif'
import daylightingHB from './assets/DaylightingHB_tn.jpg'
import rapidPatterning from './assets/FacadePatterning_tn.gif'
import training1 from './assets/BiweeklyTrainings_tn.gif'
import intermediate from './assets/Intermediate_tn.gif'
import rationalization from './assets/Geometry_tn.jpg'


const devs = [
    {
      name: 'Unit Mix Tool',
      path: '/unit-mix',
      image: unitmix,
      techstack: 'Python / Rhino API / ETO Form',
      description: `"Unit Mix Tool" automates unit division and assignment which
      is a part of feasibilty study based on the given required unit type ratio.`,
      image_1: unitmix,
      caption_1: 'The first stage of the operation starts with bay subdivision',
      text_1: 'There are 3 different types of ',
      image_2: unitmix,
      caption_2: 'The first stage of the operation starts with bay subdivision',
      text_2: '',
      image_3: unitmix,
      caption_3: 'The first stage of the operation starts with bay subdivision',
      text_3: '',
    },
    {
    name: 'Unit Finder Tool',
    path: '/unit-finder',
    image: unitfinder,
    techstack: 'Python / HTML / CSS / GH / Rhino API',
    description: `"Unit Finder Tool" helps designers to design unit plans by providing
    the most closest unit in the office unit plan library once it is queried.`,
    },
    {
      name: 'Sun Lee Webpage',
      path: '/webpage',
      image: webpage,
      techstack: 'React / JS / HTML / CSS',
      description: `Portfolio Webpage of Sun Lee containing development project,
      workflows for architectural projects.`,
    },
    {
      name: 'Notion Development',
      path: '/notion',
      image: notion,
      techstack: 'Notion',
      description: `Project and R&D management system using Notion database`,
    },
];

const workflows = [
    {
      name: 'View Analysis Tools',
      path: '/view-analysis',
      image: viewanalysis,
      techstack: 'Rhino / GH / RhinoAPI / Python',
      description: `A set of analysis workflows to assess diverse quality of views`,
    },
    {
      name: 'Area Calculation',
      path: '/area-calculation',
      image: areacal,
      techstack: 'Rhino / GH',
      description: `Area calculation automation exporting result into an excel sheet`,
    },
    {
      name: 'rapid patterning',
      path: '/rapid-patterning',
      image: rapidPatterning,
      techstack: 'Rhino / GH',
      description: `Sets of workflows to rapidly subdividing massing surface and assigning types`,
    },
    {
      name: 'Geometry Rationalization',
      path: '/geom-rationalization',
      image: rationalization,
      techstack: 'Rhino / GH',
      description: `Rationalization of Geometry Considering Constructability`,
    },
    {
      name: 'Climate Studio analysis',
      path: '/wip',
      image: cs,
      techstack: 'Rhino / GH / Climate Studio',
      description: `Various workflows to analyze the impact of environmental factors (sunglight, lighting, etc.) to the building and the surrounding`,
    },
    {
      name: 'solar hours analysis',
      path: '/wip',
      image: solarLB,
      techstack: 'Rhino / GH / Labybug Tools',
      description: `Analyzing solar hour duration of a target surface`,
    },
    {
      name: 'daylighting analysis',
      path: '/wip',
      image: daylightingHB,
      techstack: 'Rhino / GH / HoneyBee Tools',
      description: `Analyzing impact of sunlight to a target surface`,
    },
    {
      name: 'Bi-weekly Training',
      path: '/wip',
      image: training1,
      techstack: 'Rhino / GH / Space Planning Tool',
      description: `Bi-weekly trainings covering various computational design workflows`,
    },
    {
      name: 'GH Basic & Intermediate Training',
      path: '/wip',
      image: intermediate,
      techstack: 'Rhino / GH',
      description: `Basic and intermediate training on grasshopper`,
    },
    {
      name: 'Rhino.Inside.Revit Facade Tools',
      path: '/wip',
      image: facades,
      techstack: 'Rhino / GH / Revit',
      description: `Various workflows to generate facade in Revit ising Rhino.Inside.Revit`,
    },
    
];

export {devs};
export {workflows};