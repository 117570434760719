import {
  faLinkedin,
  faGithub,  
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
    faEnvelope,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './Footer.css';
import logoWhiteImage from './assets/logo.png';
import pages from '../../utils/pages';

const contacts = [
    { icon: faLocationDot, info: 'New York, NY', },
    { icon: faEnvelope, info: 'iamsunghoonlee@gmail.com', },
];

const socials = [
    { icon: faLinkedin, name: 'linkedin', url : 'https://www.linkedin.com/in/sun-sunghoon-lee', },
    { icon: faGithub, name: 'github', url : 'https://github.com/iamsunghoonlee',},
    // { icon: faFacebook, name: 'facebook', url : '',},
    // { icon: faTwitter, name: 'twitter', url : '',},
    // { icon: faInstagram, name: 'instagram', url : '',},
    // { icon: faYoutube, name: 'youtube', url : '',},
];

const navLinks = Array.from(pages.values()).filter(page => page.anchorable);

const Footer = () => {
    return (
      <footer className="site-footer">
        <div className="container grid">
          <img 
            className="site-footer-logo" 
            src={logoWhiteImage} 
            alt="Sun Lee" 
          />
          <nav className="site-footer-nav">
            <h4>Sitemap</h4>
            <ul>
              {navLinks.map((navLink, index) => 
                <li key={index}>
                  <Link to={navLink.path}>{navLink.name}</Link>
                </li>
              )}
            </ul>
          </nav>
          <div className="site-footer-contact">
            <h4>Contact</h4>
            <address>
            {contacts.map((contact, index) => 
              <p key={index}>
                <FontAwesomeIcon icon={contact.icon} /> {contact.info}
              </p>
            )}
            </address>
          </div>
          <div className="site-footer-social">
            <h4>Connect</h4>
            {socials.map((social, index) => 
              <a 
                key={index} 
                href={social.url ? social.url : `https://www.${social.name}.com`} 
                target="_blank" 
                rel="noreferrer" 
              >
                <FontAwesomeIcon icon={social.icon} size="lg" />
              </a>
            )}
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;