import DevsList from './DevsList';


const ProjectDev = () => {
  return (
    <>
      <DevsList />
    </>
  );
};

export default ProjectDev;