import './Projects.css';
import ms_full from '../assets/MS_FullImage.png'
import ms_diagram from '../assets/MS_Diagram.png'
import ms_flat1 from '../assets/MS_Flat 1.png'
import ms_flat2 from '../assets/MS_Flat 2.png'
import ms_flat_pat from '../assets/MS_Flat 3.png'
import ms_corner1 from '../assets/MS_Corner 1.png'
import ms_corner2 from '../assets/MS_Corner 2.png'
import ms_corner_pat from '../assets/MS_Corner 3.png'



const GeomRationalization = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> MIAMI SKYLINE PROJECT </h3>
                <br></br>
                <div>
                    <img src={ms_full}/>
                </div>
                <p class="project-caption"> OFFICE TOWER OF SANTANDER IN MIAMI </p>
                {/* <div class="project-text"> 
                This is an office tower project of Santander located in Miami Financial District. 
                The space is divided into office space for Santander, rental office space, and retail space. 
                </div> */}
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> SOLAR PANELS AT THE PERIMETER </h3>
                <br></br>
                <div>
                    <img src={ms_diagram}/>
                </div>
                <p class="project-caption"> SOLAR PANELS PLACED ALONG THE PERIMETER WITH EXOSKELETON STRUCTURE  </p>
                {/* <div class="project-text"> 
                </div> */}
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> SOLAR PANELS AT THE FLAT PORTION OF THE TOWER </h3>
                <br></br>
                <div>
                    <img src={ms_flat1}/>
                </div>
                <div>
                    <img src={ms_flat2}/>
                </div>
                <div>
                    <img src={ms_flat_pat}/>
                </div>
                {/* <p class="project-caption"> DETAILED VIEWS OF SOLAR PANELS AT THE FLAT PERIMETER </p> */}
                {/* <div class="project-text"> 
                This analysis is assessing view toward a ranged view. The analysis is based on rays starting from the center of each subdivided surface toward a set directions from it.
                Then it calculates if the rays are proceeding without obstructed by the surrounding context. Then the number of rays that were able to reach points from each surface is assessed.<br/>
                Based on the assessed number, the script generates view score.
                </div> */}
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> SOLAR PANELS AT THE CORNER PORTION OF THE TOWER </h3>
                <br></br>
                <div>
                    <img src={ms_corner1}/>
                </div>
                <div>
                    <img src={ms_corner2}/>
                </div>
                {/* <div>
                    <img src={ms_corner_pat}/>
                </div> */}
                {/* <p class="project-caption"> DETAILED VIEWS OF SOLAR PANELS AT THE FLAT PERIMETER </p> */}
                {/* <div class="project-text"> 
                This analysis is assessing view toward a ranged view. The analysis is based on rays starting from the center of each subdivided surface toward a set directions from it.
                Then it calculates if the rays are proceeding without obstructed by the surrounding context. Then the number of rays that were able to reach points from each surface is assessed.<br/>
                Based on the assessed number, the script generates view score.
                </div> */}
                <br></br>
            </div>
        </section>
    );
}

export default GeomRationalization;