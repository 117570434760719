import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './ProjectCard.css';
import pages from '../../../utils/pages';

const ProjectCard = ({ project }) => {
  return (
    <article className="project-card">
      <div className="project-card-image">
        <img src={project.image} alt={project.name} />
      </div>
      <div className="project-card-header">
        <h3>{project.name}</h3>
      </div>
      <div className="project-card-body-footer">
        <p>{project.techstack}</p>
        <p>{project.description}</p>
        <Link to={project.path}>
          Learn More <FontAwesomeIcon icon={faArrowRightLong} className="project-card-arrow"/>
        </Link>
      </div>
    </article>
  );
};

export default ProjectCard;