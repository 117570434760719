import './Projects.css';
import area_1 from '../assets/ac_1.png'
import area_2 from '../assets/ac_2.jpg'


const AreaCalculation = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> AREA CALCULATION BASED ON PROGRAM & PROJECT LEVELS </h3>
                <br></br>
                <div>
                    <img src={area_1}/>
                    <img src={area_2}/>
                </div>
                <p class="project-caption"> Area Calculation Result Generated in Excel </p>
                <div class="project-text"> 
                The script that used in this workflow takes massing geometries stored inside an organized layer structure in Rhino and generates an excel sheet with area information of each program and level. 
                By automatically generating floor area data for a design option, this script saves time and reduces possible mistake on the calculation.
                </div>
                <br></br>
            </div>

        </section>
    );
}

export default AreaCalculation;