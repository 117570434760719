import './Projects.css';
import view_img1 from '../assets/va_view-length.png'
import view_img2 from '../assets/va_view-landmark.jpg'
import view_img3 from '../assets/va_view-range2.jpg'
import view_img4 from '../assets/va_view-range1.gif'


const ViewAnalysis = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> TYPE 1. VIEW ANALYSIS - VIEW LENGTH </h3>
                <br></br>
                <div>
                    <img src={view_img1}/>
                </div>
                <p class="project-caption"> View Length Assessed for Two Different Massing Schemes </p>
                <div class="project-text"> 
                This analysis is assessing the length of unobstructed view from each surface. The analysis is based on a ray starting from the center of each subdivided surface.
                Then it calculates how much the ray is proceeding without obstructed by the surrounding context.<br/>
                Based on the assessed length, the script generates view score.
                </div>
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> TYPE 2. VIEW ANALYSIS - VIEW TOWARD A POINT </h3>
                <br></br>
                <div>
                    <img src={view_img2}/>
                </div>
                <p class="project-caption"> View Quality Assessed Toward a Point Assessed </p>
                <div class="project-text"> 
                This analysis is assessing view toward a monumental view. The analysis is based on a ray starting from the center of each subdivided surface.
                Then it calculates if the ray is proceeding without obstructed by the surrounding context. Then with the surfaces having view toward the point, the quality is assessed with the angle toward the point<br/>
                Based on the assessed angle, the script generates view score.
                </div>
                <br></br>
            </div>

            <br></br>
            <div class="project-contents">
                <h3> TYPE 3. VIEW ANALYSIS - VIEW TOWARD A RANGED VIEW </h3>
                <br></br>
                <div>
                    <img src={view_img4}/>
                </div>
                <p class="project-caption"> View Quality assessed Toward a ranged view </p>
                <div class="project-text"> 
                This analysis is assessing view toward a ranged view. The analysis is based on rays starting from the center of each subdivided surface toward a set directions from it.
                Then it calculates if the rays are proceeding without obstructed by the surrounding context. Then the number of rays that were able to reach points from each surface is assessed.<br/>
                Based on the assessed number, the script generates view score.
                </div>
                <br></br>
            </div>
        </section>
    );
}

export default ViewAnalysis;