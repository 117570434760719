import './Projects.css';
import um_img1 from '../assets/UnitMix_01.gif'
import um_img2 from '../assets/UnitMix_02.gif'
import um_img3 from '../assets/UnitMix_03.gif'
import um_img4_1 from '../assets/UnitMix_04-1.png'
import um_img4_2 from '../assets/UnitMix_04-2.png'
import um_img5 from '../assets/UnitMix_05.gif'


const UnitMix = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> 1. PARAMETRIC DIVISION OF FLOOR </h3>
                <br></br>
                <div>
                    <img src={um_img1}/>
                </div>
                <p class="project-caption"> Parametric adjustment of floor plan division </p>
                <div class="project-text"> 
                    The software can transition the floor outline into bays of different sizes, considering the changing configuration of cores. It can also adjust the bay profile based on the required depth and width.  
                </div>
                <br></br>
            </div>
            {/* text types : Section / Image caption / Description */}

            <div class="project-contents">
                <h3> 2. SCORING THE BAYS </h3>
                <br></br>
                <div>
                    <img src={um_img2}/>
                </div>
                <p class="project-caption"> Scoring Bays within the Floor Plan </p>

                <div class="project-text">
                    View score can measured by a point for a monumental view or a group of points for a ranged view.
                    The logic could also detect obstacles and apply the condition to the view score.
                </div>
                <div class="project-text">
                    Position score is based on the bays' relative location within the floor plan. 
                    The scoring system is designed to prioritize the bays at the corner, but it's also adaptable to more complex floor plan types, ensuring its applicability across various scenarios.
                    This logic could be expanded to consider other factors, such as environmental conditions.
                </div>

                <br></br>
            </div>

            <div class="project-contents">
                <h3> 3. BAY PROCESSING & UNIT NUMBER CALCULATION </h3>
                <br></br>
                <div>
                    <img src={um_img3}/>
                </div>
                <p class="caption"> Calculating Unit Number close to the target Unit Mix </p>
                    
                <div class="project-text"> 
                    
                </div>
                <br></br>
            </div>

            {/* <div class="project-contents">
                <h3> 4. UNIT ASSIGNMENT </h3>
                <br></br>
                <div>
                    <img src={um_img4}/>
                </div>
                <p class="project-caption"> Generating alternative assignment options for unit assignment </p>

                <div class="project-text">
                    The software plays a crucial role in the unit assignment process, using the Unit Number Calculation to efficiently consider the combined score generated in '2. SCORING BAYS.'
                    The purple numeric value inside the parenthesis indicates the combined score.
                </div>
                <br></br>
            </div> */}


            <div class="project-contents">
                <h3> 4. OPTIMIZATION </h3>
                <br></br>
                <div>
                    <img src={um_img4_1}/>
                </div>
                <p class="project-caption"> Optimized Process 1 </p>
                <br></br>
                <br></br>

                <div>
                    <img src={um_img4_2}/>
                </div>
                <p class="project-caption"> Optimized Process 2 </p>
                
                <div class="project-text"> 
                    Various unit layouts generated in "3. UNIT ASSIGNMENT" can be evaluated using customized metrics to get closer to the output. 
                    A customized Genetic Algorithm has been fine tuned to be applied to this process. 
                </div>
                <br></br>
            </div>


            <div class="project-contents">
                <h3> 5. GENERATING OPTIMIZED SCHEME </h3>
                <br></br>
                <div>
                    <img src={um_img5}/>
                </div>
                <p class="project-caption"> Generating Multiple Scheme Using Optimized Generative Algorithm</p>
                
                <div class="project-text"> 
                    With the optimized generative algorithm, you can generate Unit Mix Ratio and Unit Average Area that is closest to the target values.
                </div>
                <br></br>
            </div>
        </section>
    );
}

export default UnitMix;