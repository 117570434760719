import { Link } from 'react-router-dom';
import sunlee from './assets/sun-lee.jpg';
import './Intro.css';
import pages from '../../../utils/pages';

const Intro = () => {
  return (
    <section className="intro">
      <div className="container">
        <img 
          className="intro-img" 
          src={sunlee} 
          alt="Sun Photo" 
        />
      </div>
      <div className="container">
        <div className="intro-information">
          <h3>Hi, I am </h3>
          <div className="link-container">
            <Link className="about-sun" to={pages.get('about').path}>Sun Lee</Link>
          </div>
          <p>
            I am a Developer/Computational Designer 
            focusing on automating/analyzing spatial matters.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Intro;