import { Link } from 'react-router-dom';
import './DevsList.css';
import pages from '../../../utils/pages';
import ProjectCard from './ProjectCard';
import { devs } from '../ProjectAsset/ProjectAsset';


const DevsList = () => {
  return (
    <section className="container grid project-lists">
      {devs.map((dev, index) => 
        <ProjectCard key={index} project={dev} />
      )}
    </section>
  );
};

export default DevsList;
