import './Projects.css';
import patterning_1 from '../assets/rp_1.png'
import patterning_2 from '../assets/rp_2.jpg'
import patterning_3 from '../assets/rp_3.jpg'


const RapidPatterning = () => {
    return(
        
        <section class="container grid project-contents">
            <br></br>
            <div class="project-contents">
                <h3> RAPID PATTERNING </h3>
                <br></br>
                <div>
                    <img src={patterning_2}/>
                    <img src={patterning_3}/>
                    <img src={patterning_1}/>
                </div>
                <p class="project-caption"> VARIOUS SURFACE PATTERNING WITH A PATTERN MODULE </p>
                <div class="project-text"> 
                For the next step, you should select numbers from the list that represents the selected surfaces from the subdivision. 
                As you could see from the image, if you fill “0, 1, 2, 3, 8, 13, 15, 16, 17, 18, 19, 22” in the panel, you will have the pattern modules as shown. 
                Each number indicates a relative surface location in specific position. 
                The script will duplicate this pattern module based on the numbers in horizontal and vertical for the original subdivided surface.
                </div>
                <br></br>
            </div>

        </section>
    );
}

export default RapidPatterning;