import WorkflowList from './WorkflowList';


const ProjectWorkflow = () => {
  return (
    <>
      <WorkflowList />
    </>
  );
};

export default ProjectWorkflow;